// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.specials-img {
  float: left;
  width: 100%;
  padding: 40px;
}

.card-img-container {
  padding: 0px 0px !important;
}

.card-img-container:hover {
  z-index: 999;
  position: relative;
  text-align: center;
  display: flex;
}

.card-img-container:hover [class*="special-cookie-"] {
  display: none;
}

.card-img:hover {
  height: 50vh;
  width: 50vw;
}

[class*="special-cookie-"] {
  text-align: left;
  width: 100%;
  padding-left: 10px;
}

.special-cookie-title {
  font-weight: 700;
}

@media only screen and (max-width: 600px) {
}
`, "",{"version":3,"sources":["webpack://./src/main/specials/specials.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,aAAa;AACf;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;AACA","sourcesContent":[".specials-img {\n  float: left;\n  width: 100%;\n  padding: 40px;\n}\n\n.card-img-container {\n  padding: 0px 0px !important;\n}\n\n.card-img-container:hover {\n  z-index: 999;\n  position: relative;\n  text-align: center;\n  display: flex;\n}\n\n.card-img-container:hover [class*=\"special-cookie-\"] {\n  display: none;\n}\n\n.card-img:hover {\n  height: 50vh;\n  width: 50vw;\n}\n\n[class*=\"special-cookie-\"] {\n  text-align: left;\n  width: 100%;\n  padding-left: 10px;\n}\n\n.special-cookie-title {\n  font-weight: 700;\n}\n\n@media only screen and (max-width: 600px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
