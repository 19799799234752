// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iframe {
  width: 100%;
  height: 100%;
}

.contact-us-form {
  height: 750px;
}

button {
  margin: 5px;
}

.order-now-page-container {
  width: 40vw;
}

@media only screen and (max-width: 900px) {
  .order-now-page-container {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/main/order-now/order-now.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":["iframe {\n  width: 100%;\n  height: 100%;\n}\n\n.contact-us-form {\n  height: 750px;\n}\n\nbutton {\n  margin: 5px;\n}\n\n.order-now-page-container {\n  width: 40vw;\n}\n\n@media only screen and (max-width: 900px) {\n  .order-now-page-container {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
